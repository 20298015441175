import React from 'react';
import Footer from '../Footer/Footer';
import Icons from '../Icons/Icons';
import Navbar from '../Navbar/Navbar';

const ServicesPage = () => {
  return (
      <>
          <Navbar/>
         <Icons/>
          <Footer/>
      </>
  )
};

export default ServicesPage;
