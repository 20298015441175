import React from 'react';
import './Hands.css'
import hands from '../Hands/hands.jpeg'

const Hands = () => {
  return (
      <>
          <section className='hands'>
            <div className='hands-image' data-aos="fade-up">
            {/* <img src={hands} alt="" /> */}
            </div>
          </section>
      </>
  )
};

export default Hands;
